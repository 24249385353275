.get-referral-code-wrapper {
  width: 50%;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper h4 {
  text-align: center;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .txt-phone {
  width: 270px;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .btn-getcode-wrapper {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--hkbn-orange);
  color: white;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .txt-referral-code {
  width: 220px;
  color: var(--hkbn-orange);
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .btn-copy-wrapper {
  width: 100px;
  background-color: var(--hkbn-orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .btn-copy-wrapper .icon-copy-wrapper {
  width: 30px;
  margin-right: 10px;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .error-message {
  color: red;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .share-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.get-referral-code-wrapper .get-referral-code-inner-wrapper .share-buttons-wrapper .button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid var(--hkbn-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .get-referral-code-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .get-referral-code-wrapper .get-referral-code-inner-wrapper {
    width: 100%;
  }

  .get-referral-code-wrapper .get-referral-code-inner-wrapper h4 {
    font-size: 1.3rem;
  }

  .get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .txt-phone {
    width: calc(100% - 50px);
  }

  .get-referral-code-wrapper .get-referral-code-inner-wrapper .text-box-wrapper .txt-referral-code {
    width: calc(100% - 100px);
    padding: 0 10px;
    text-align: center;
  }
}