.main-wrapper {
  margin-top: 20px;
}

.main-wrapper .inner-wrapper {
  display: flex;
  justify-content: space-between;
}

.main-wrapper .inner-wrapper .referral-record-wrapper h3 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .desc {
  display: flex;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .descMobile {
  display: none;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .descApp {
  display: none;
}

.main-wrapper .inner-wrapper .btn-history-wrapper {
  margin-left: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-sizing: border-box;
  width: 350px;
}

.main-wrapper .inner-wrapper .btn-history-icon {
  width: 60px;
  height: 60px;
}

.main-wrapper .inner-wrapper .img-myhkbn-link {
  display: flex;
}

@media screen and (max-width: 1024px) {
  .main-wrapper .inner-wrapper .btn-history-wrapper {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper .inner-wrapper .referral-record-wrapper .desc {
    display: none;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .descMobile {
    display: flex;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .descApp {
    display: flex;
  }

  .main-wrapper .inner-wrapper .btn-history-wrapper {
    display: flex;
    width: 350px;
  }

  .main-wrapper .inner-wrapper .img-myhkbn-link {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper {
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .desc {
    display: none;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .descMobile {
    display: flex;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .descApp {
    display: flex;
  }

  .main-wrapper .inner-wrapper .btn-history-wrapper {
    display: flex;
    margin-left: 0;
    margin-top: 20px;
    width: 350px;
  }

  .main-wrapper .inner-wrapper .img-myhkbn-link {
    display: none;
  }
}