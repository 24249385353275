.main-wrapper .inner-wrapper .welcome-message {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .welcome-message h2 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .welcome-message .btn-signout {
  font-size: 30px;
  margin-left: 20px;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper {
  display: flex;
  align-items: center;
  padding: 20px 0;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 {
  margin: 0;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 div {
  display: inline-block;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 span {
  color: var(--hkbn-orange);
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .buttons-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .buttons-wrapper .btn-copy-wrapper {
  margin-left: 20px;
  background-color: var(--hkbn-orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 30px;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .buttons-wrapper .btn-copy-wrapper .icon-copy-wrapper {
  width: 30px;
  margin-right: 10px;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .right-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-left: 300px;
}

.main-wrapper .inner-wrapper .share-referral-code-wrapper .right-wrapper .button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid var(--hkbn-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper {
  margin: 40px 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper h3 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper {
  margin-top: 20px;
  overflow-x: auto;
  position: relative;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper:hover::after {
  opacity: 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  display: none;
  background: rgba(39, 47, 56, 0.2) url("../../../assets/drag.svg") center 10px no-repeat;
  transition: opacity 0.15s;
  background-size: 50% 160px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper {
  width: 1250px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row {
  background-color: #fff;
  display: grid;
  grid-template-columns: 100px 1fr 1fr 100px 2fr 1fr;
  align-items: center;
  min-height: 50px;
  padding: 20px 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row:nth-child(n) {
  margin-bottom: 3px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row:last-child {
  margin-bottom: 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row.header {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row div:last-child {
  justify-self: end;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 5px;
  position: relative;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.pending {
  background-color: #f5ca02;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.fail {
  background-color: #d9041d;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.success {
  background-color: #28a628;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.reward-grant {
  background-color: #28a628;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.reward-grant img:last-child {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .icon-wrapper {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper div::after {
  content: "+";
  margin: 0 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper div:last-child::after {
  content: "";
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .btn-record-info {
  display: inline-block;
  background-color: var(--hkbn-blue);
  padding: 10px 60px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .main-wrapper .inner-wrapper .share-referral-code-wrapper .right-wrapper {
    margin-left: 30px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper::after {
    display: block;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .btn-record-info {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper .inner-wrapper .share-referral-code-wrapper {
    display: block;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 {
    font-size: 1.3rem;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 span {
    line-height: 2em;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .btn-copy-wrapper {
    padding: 10px 10px;
    width: 150px;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .right-wrapper {
    flex-wrap: wrap;
    margin-left: 0;
    margin-top: 20px;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .right-wrapper>div:first-child {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .btn-record-info {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper .welcome-message h2 {
    font-size: 1rem;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper {
    display: block;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper h4 {
    flex: 1 0 50%;
    font-size: 1.2rem;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .buttons-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .buttons-wrapper .btn-copy-wrapper {
    margin-left: 0;
  }

  .main-wrapper .inner-wrapper .share-referral-code-wrapper .left-wrapper .btn-signout {
    margin-top: 10px;
  }
}