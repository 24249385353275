.main-wrapper .inner-wrapper .swiper-container .banner-wrapper {
  height: 320px;
  display: flex;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper {
  position: relative;
  width: 50%;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper .image-wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper .mgm-logo-wrapper {
  position: absolute;
  left: 0;
  bottom: 10px;
  padding: 20px 100px 20px 20px;
  width: 370px;
  clip-path: polygon(0 0, 88% 0%, 75% 100%, 0% 100%);
  -webkit-clip-path: polygon(0 0, 88% 0%, 75% 100%, 0% 100%);
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper {
  background-color: var(--hkbn-blue);
  width: 50%;
  position: relative;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  margin: 40px 50px;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper {
  display: flex;
  align-items: center;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper::after {
  content: "+";
  margin: 0 20px;
  font-size: 1.5rem;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper:last-child::after {
  content: unset;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper .image-wrapper {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  flex-shrink: 0;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper .desc {
  margin-left: 10px;
  font-size: 1.5rem;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper {
  display: flex;
  margin-top: 20px;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper a {
  margin: 0 10px;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper a .button {
  display: block;
  padding: 10px 40px;
  border-radius: 10px;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper a:first-child {
  margin-left: 0;
}
.main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper a:last-child {
  margin-right: 0;
}
.custom-swiper-pagination {
  bottom: 20px !important;
  left: calc(50% + 50px) !important;
  width: 20% !important;
  text-align: left;
}
.custom-swiper-pagination-bullet {
  opacity: 1;
  margin: 0 8px !important;
}
.custom-swiper-pagination-bullet-active {
  cursor: pointer;
}
.swiper-control-button {
  position: absolute;
}
.swiper-button-next {
  background-image: url("../../assets/swiper-btn-next.svg");
}
.swiper-button-prev {
  background-image: url("../../assets/swiper-btn-prev.svg");
}
@media screen and (max-width: 768px) {
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper {
    height: calc(100vh - 108px);
    display: block;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper {
    width: 100%;
    height: 50%;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper .mgm-logo-wrapper {
    width: 50%;
    clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
    padding: 10px 50px 10px 10px;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper {
    position: relative;
    margin: 50px;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper h4 {
    margin-top: 20px;
  }
  .custom-swiper-pagination {
    bottom: 10px !important;
    left: 0px !important;
    right: 0 !important;
    margin: auto;
  }
}
@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper .mgm-logo-wrapper {
    padding: 10px 20px 10px 10px;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper {
    margin: 50px 10px;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .title * {
    font-size: 1.6rem;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .subtitle * {
    font-size: 1.2rem;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination {
    display: flex;
    flex-wrap: wrap;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 25px;
    position: relative;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper:first-child {
    margin-top: 0;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper .image-wrapper {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .promo-combination .item-wrapper .desc {
    font-size: 1.2rem;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper {
    justify-content: center;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper .content-wrapper .button-wrapper .button {
    padding: 10px 10px !important;
    text-align: center;
    font-size: 15px;
    white-space: pre;
  }
}
@media screen and (max-width: 320px) {
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .left-wrapper {
    height: 35%;
  }
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper .right-wrapper {
    height: 65%;
  }
  .swiper-control-button {
    top: 35%;
  }
}
