.main-wrapper {
  position: relative;
  margin-left: 20px;
}

.main-wrapper .btn-share {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.main-wrapper .share-buttons-wrapper {
  position: absolute;
  top: 70px;
  left: -65px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  padding: 10px;
}

.main-wrapper .share-buttons-wrapper::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  margin: auto;
}

.main-wrapper .share-buttons-wrapper .btn-share-social-media {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

@media screen and (max-width: 1024px) {
  .main-wrapper .share-buttons-wrapper {
    left: unset;
    right: 0;
  }

  .main-wrapper .share-buttons-wrapper::before {
    left: 135px;
  }
}