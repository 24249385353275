.login-wrapper {
  width: 50%;
  padding: 0 100px;
}

.login-wrapper .login-inner-wrapper h4 {
  text-align: center;
}

.login-wrapper .login-inner-wrapper h5 {
  text-align: center;
}

.login-wrapper .login-inner-wrapper .txt-phone {
  margin-top: 20px;
  width: 100%;
}

.login-wrapper .login-inner-wrapper .txt-pps {
  margin-top: 10px;
  width: 100%;
}

.login-wrapper .login-inner-wrapper .error-message {
  color: red;
}

.login-wrapper .login-inner-wrapper .btn-forget-account {
  margin-top: 10px;
  text-decoration: underline;
  text-align: center;
}

.login-wrapper .login-inner-wrapper .btn-login {
  margin-top: 20px;
  width: 100%;
  background-color: var(--hkbn-orange);
  color: white;
  border-radius: 10px;
  padding: 10px 0;
  text-align: center;
}

.login-wrapper .login-inner-wrapper .btn-fb-login {
  margin-top: 20px;
  text-decoration: underline;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .login-wrapper {
    width: 50%;
    padding-left: 0;
    padding-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .login-wrapper {
    width: 100%;
  }

  .login-wrapper .login-inner-wrapper h3 {
    font-size: 1.5rem;
  }

  .login-wrapper .login-inner-wrapper h4 {
    font-size: 1.3rem;
  }
}