:root {
    --schema-max-width: 1250px;
    --schema-title-size: 36px;
}

@media screen and (max-width: 1024px) {
    :root {
        --schema-max-width: 80%;
        --schema-title-size: 26px;
    }
}

@media screen and (max-width: 640px) {
    :root {
        --schema-max-width: 80%;
        --schema-title-size: 26px;
    }
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    font-family: Microsoft JhengHei, Arial, Helvetica, sans-serif;
}

#app {
    width: 100%;
    height: 100%;
}

img {
    max-width: 100%;
    max-height: 100%;
}

button {
    border: 0;
}

.a-remove-default-style,
.a-remove-default-style:hover {
    color: unset;
    text-decoration: unset;
}

.content-title {
    font-size: var(--schema-title-size);
    font-weight: bold;
    text-align: center;
}

.button {
    cursor: pointer;
}

div[style*="--aspect-ratio"] {
    padding-top: calc(100% / (var(--aspect-ratio)));
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
   supported by Chrome and Opera */
}

.textbox {
    width: 320px;
    height: 50px;
    border: 0;
    padding: 0 30px;
    display: block;
    margin: 0;
}