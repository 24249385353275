.main-wrapper {
  padding: 50px 0;
}

.main-wrapper .inner-wrapper {
  max-width: var(--schema-max-width);
  margin: 0 auto;
}

.main-wrapper .inner-wrapper .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-wrapper .inner-wrapper .title-wrapper h2 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .title-wrapper .btn-expand-qna {
  margin-right: 40px;
  font-size: 30px;
}

.main-wrapper .inner-wrapper .questions-wrapper {
  margin-top: 20px;
}

.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper {
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  grid-row-gap: 10px;
  padding: 30px 0;
  border-bottom: 1px solid var(--hkbn-blue);
}

.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper:last-child {
  border-bottom: 0;
}

.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .question-no,
.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .question,
.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .btn-expand {
  font-size: 20px;
  font-weight: bold;
}

.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .answer {
  grid-column: 2/2;
  overflow: hidden;
  max-height: 0;
  transition: 0.5s max-height;
}

.main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .answer.expand {
  max-height: 500px;
}

@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper h2 {
    font-size: 1.5rem;
  }

  .main-wrapper .inner-wrapper .questions-wrapper .question-wrapper {
    grid-template-columns: 25px 1fr 15px;
  }

  .main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .question-no,
  .main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .question,
  .main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .btn-expand {
    font-size: 15px;
  }

  .main-wrapper .inner-wrapper .questions-wrapper .question-wrapper .answer {
    font-size: 15px;
  }
}