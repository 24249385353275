.main-wrapper .inner-wrapper .swiper-container .banner-wrapper {
  height: 480px;
  display: flex;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
.custom-swiper-pagination {
  position: absolute;
}
.custom-swiper-pagination-bullet {
  opacity: 1;
  margin: 0 8px !important;
}
.custom-swiper-pagination-bullet-active {
  cursor: pointer;
}
.swiper-control-button {
  position: absolute;
}
.swiper-button-next {
  background-image: url("../../assets/swiper-btn-next.svg");
}
.swiper-button-prev {
  background-image: url("../../assets/swiper-btn-prev.svg");
}
@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper .swiper-container .banner-wrapper {
    height: 320px;
  }
}
