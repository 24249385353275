.main-wrapper {
  padding: 50px 0;
}

.main-wrapper .inner-wrapper {
  max-width: var(--schema-max-width);
  margin: 0 auto;
}

.main-wrapper .inner-wrapper .lang {
  display: inline-block;
  margin-top: 10px;
  padding-right: 10px;
}

.main-wrapper .inner-wrapper table,
.main-wrapper .inner-wrapper th,
.main-wrapper .inner-wrapper td {
  border: 1px solid black;
}

.main-wrapper .inner-wrapper .TableDiv1 {
  text-align: center;
  width: 100%;
}

.main-wrapper .inner-wrapper .tncTable1 {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.main-wrapper .inner-wrapper p {
  margin: 15px 0;
}

.main-wrapper .inner-wrapper li {
  padding-top: 10px;
}