.row {
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px 200px 2fr 1fr 50px;
  align-items: center;
  min-height: 50px;
  padding: 20px 20px;
}

.row.expand {
  align-items: flex-start;
}

.row:nth-child(n) {
  margin-bottom: 3px;
}

.row:last-child {
  margin-bottom: 0;
}

.row.header {
  font-weight: bold;
}

.row .referrer-name.nc-order::after {
  content: "*";
  margin-left: 3px;
}

.row div:last-child {
  justify-self: end;
}

.row .status-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 5px;
  position: relative;
}

.row .status-icon.pending {
  background-color: #f5ca02;
}

.row .status-icon.fail {
  background-color: #d9041d;
}

.row .status-icon.success {
  background-color: #28a628;
}

.row .status-icon.reward-grant {
  background-color: #28a628;
}

.row .status-icon.reward-grant img:last-child {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 20px;
}

.row .icon-wrapper {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
}

.row .service-and-status {
  display: flex;
}

.row .service-and-status *:not(:first-child) {
  margin-left: 10px;
}

.row .text-icon-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.row .text-icon-wrapper .item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.row .text-icon-wrapper .item:not(:first-child) .text {
  margin-left: 10px;
}

.row .text-icon-wrapper .item .text {
  margin-right: 10px;
}

.row .text-icon-wrapper .item .icon-wrapper {
  width: unset;
  margin-left: 10px;
}

.row .text-icon-wrapper>div:not(:first-child)::before {
  content: "+";
}

.row .btn-expand {
  font-size: 30px;
}

.row .btn-record-info {
  display: inline-block;
  background-color: var(--hkbn-blue);
  padding: 10px 60px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .row {
    grid-template-columns: 1fr 1.25fr 3fr 1fr 1fr;
  }
}

@media screen and (max-width: 640px) {
  .row {
    padding: 20px 5px;
    grid-column-gap: 10px;
  }

  .row .status-icon {
    width: 25px;
    height: 25px;
    box-sizing: border-box;
  }

  .row .icon-wrapper {
    width: 25px;
    height: 25px;
  }

  .row .service-and-status *:not(:first-child) {
    margin-left: 0;
  }

  .row .text-icon-wrapper {
    flex-wrap: wrap;
  }

  .row .text-icon-wrapper .item {
    width: 100%;
    justify-content: center;
  }

  .row .text-icon-wrapper .item:not(:first-child) .text {
    margin-left: 0px;
  }

  .row .text-icon-wrapper .item .text {
    margin-right: 0px;
  }

  .row .text-icon-wrapper .item .icon-wrapper {
    margin-left: 0;
  }

  .row .text-icon-wrapper>div:not(:first-child)::before {
    width: 100%;
    text-align: center;
  }
}