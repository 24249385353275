.main-wrapper {
  padding: 50px 0;
  background-color: #dee8f0;
}

.main-wrapper .inner-wrapper {
  max-width: var(--schema-max-width);
  margin: 0 auto;
}

.main-wrapper .inner-wrapper h2 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .btn-back {
  color: var(--hkbn-blue);
  font-size: 20px;
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .btn-back .back-icon {
  margin-right: 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper {
  margin: 40px 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper h3 {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .sort-wrapper select {
  height: 44px;
  width: 300px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper .date-picker-wrapper input {
  height: 44px;
  margin: 0 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .btn-apply {
  margin-left: 20px;
  background-color: var(--hkbn-blue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 50px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper {
  margin-top: 20px;
  position: relative;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper:hover::after {
  opacity: 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  display: none;
  background: rgba(39, 47, 56, 0.2) url("../../assets/drag.svg") center 10px no-repeat;
  transition: opacity 0.15s;
  background-size: 50% 160px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row {
  background-color: #fff;
  display: grid;
  grid-template-columns: 200px 200px 1.5fr 1fr;
  align-items: center;
  min-height: 50px;
  padding: 20px 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row.expand {
  align-items: flex-start;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row:nth-child(n) {
  margin-bottom: 3px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row:last-child {
  margin-bottom: 0;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row.header {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 5px;
  position: relative;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.reward-grant {
  background-color: #28a628;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon.reward-grant img:last-child {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .icon-wrapper {
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .service-and-status {
  display: flex;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .service-and-status *:not(:first-child) {
  margin-left: 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper.plus::after {
  content: "+";
  margin: 0 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper.plus:last-child::after {
  content: "";
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper .expand-reward-wrapper .item {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper .expand-reward-wrapper .item .icon {
  height: 38px;
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .btn-expand {
  font-size: 30px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .btn-record-info {
  display: inline-block;
  background-color: var(--hkbn-blue);
  padding: 10px 60px;
  border-radius: 10px;
  color: #fff;
  text-align: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper {
  display: flex;
  margin-top: 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  justify-content: space-between;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper {
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: unset;
  padding: 5px;
  position: relative;
  margin-right: 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon.pending {
  background-color: #f5ca02;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon.fail {
  background-color: #d9041d;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon.success {
  background-color: #28a628;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon.reward-grant {
  background-color: #28a628;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon.reward-grant img:last-child {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 20px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging {
  flex: 1 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging * {
  margin-left: 10px;
  color: var(--hkbn-blue);
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging .btn-page.active {
  color: var(--hkbn-orange);
}

.main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging .btn-page:hover {
  color: var(--hkbn-orange);
}

.main-wrapper .inner-wrapper .referral-record-wrapper .nc-order-desc {
  margin-top: 10px;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .button-back-wrapper {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper .inner-wrapper .referral-record-wrapper .button-back-wrapper .btn-back {
  margin-left: 20px;
  background-color: var(--hkbn-orange);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 10px 50px;
}

@media screen and (max-width: 1024px) {
  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .sort-wrapper select {
    width: unset;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row {
    grid-template-columns: 1fr 1.25fr 3fr 2fr;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status {
    flex: 1 0 60%;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging {
    flex: 1 0 40%;
  }
}

@media screen and (max-width: 768px) {
  .main-wrapper .inner-wrapper {
    max-width: 100%;
    margin: 0;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper {
    max-width: var(--schema-max-width);
    margin: 0 auto;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper {
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .sort-wrapper {
    width: 100%;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .sort-wrapper select {
    width: 100%;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper {
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: space-between;
    width: 100%;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .btn-apply {
    box-sizing: border-box;
    padding: 10px 30px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row.header {
    font-size: 12px;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper .date-picker-wrapper {
    flex: 1 0 90%;
    margin-bottom: 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper .date-picker-wrapper div {
    width: 100%;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .date-filter-wrapper .date-picker-wrapper div input {
    width: calc(100% - 10px);
    margin: 0 0 0 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .header-wrapper .filter-wrapper .btn-apply {
    flex: 1;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper {
    word-break: break-word;
    font-size: 14px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row {
    padding: 20px 5px;
    grid-column-gap: 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .status-icon {
    width: 25px;
    height: 25px;
    box-sizing: border-box;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .icon-wrapper {
    width: 25px;
    height: 25px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .service-and-status *:not(:first-child) {
    margin-left: 0;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .table-wrapper .table-inner-wrapper .row .text-icon-wrapper {
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper {
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status {
    flex: 1 0 100%;
    padding: 0 10px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status div:first-child {
    flex: 1 0 100%;
    margin-bottom: 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .referral-status .status-wrapper .status-icon {
    width: 25px;
    height: 25px;
    box-sizing: border-box;
    flex: 1 0 25px;
    margin: 0;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging {
    flex: 1 0 100%;
    justify-content: flex-end;
    margin-top: 20px;
    padding: 10px;
    box-sizing: border-box;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .bottom-wrapper .paging * {
    margin-left: 10px;
  }

  .main-wrapper .inner-wrapper .referral-record-wrapper .nc-order-desc {
    margin-left: 10px;
  }
}