.main-wrapper .inner-wrapper .tab-buttons-wrapper {
  display: flex;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button {
  flex: 1;
  padding: 5px 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button.active .inner-wrapper {
  border-right: unset !important;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button .inner-wrapper {
  text-align: center;
  padding: 10px 0;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button .inner-wrapper:not(:last-child) {
  border-right: 2px solid white;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button .inner-wrapper .text {
  font-size: 20px;
  font-weight: bold;
}

.main-wrapper .inner-wrapper .tab-buttons-wrapper .tab-button .active-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
}

.main-wrapper .inner-wrapper .video-section-wrapper {
  display: flex;
  background-color: white;
  padding: 50px;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  margin-right: 20px;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .subtitle {
  font-weight: bold;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .subtitle span {
  color: var(--hkbn-orange);
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .point-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .point-wrapper .point-no {
  text-align: center;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  flex-shrink: 0;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .point-wrapper .icon-wrapper {
  height: 36px;
  margin-left: 20px;
  flex-shrink: 0;
}

.main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .point-wrapper .desc {
  margin-left: 20px;
  text-align: left;
}

.main-wrapper .inner-wrapper .video-section-wrapper .right-wrapper {
  width: 50%;
}

.main-wrapper .inner-wrapper .video-section-wrapper .right-wrapper .youtube-player {
  padding-top: calc(100% / (16/9));
  margin-top: 20px;
  position: relative;
  height: 0;
  width: 100%;
}

.main-wrapper .inner-wrapper .video-section-wrapper .right-wrapper .youtube-player iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .main-wrapper .inner-wrapper .video-section-wrapper {
    flex-wrap: wrap;
  }

  .main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper {
    flex: 1 0 100%;
    order: 1;
    margin-top: 40px;
    margin-right: 0;
  }

  .main-wrapper .inner-wrapper .video-section-wrapper .left-wrapper .point-wrapper {
    margin-bottom: 20px;
  }

  .main-wrapper .inner-wrapper .video-section-wrapper .right-wrapper {
    flex: 1 0 100%;
    order: 0;
  }
}

@media screen and (max-width: 640px) {
  .main-wrapper .inner-wrapper .video-section-wrapper {
    padding: 15px;
  }
}